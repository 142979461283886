/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */

import axios from 'axios';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import forEach from 'lodash/forEach';
import lodashGet from 'lodash/get';
import { singularize, pluralize } from 'inflection';
import Cookies from 'js-cookie';

/* declarations */
let client;
let BASE_URL;
let userId;
let jwtToken;
let expiryTime;
let role;
let authUrl;
let apps;
let locale;
let themeColor;
let logoUrl;
let supportPhone;
let phone;
let organization;
let paymentMethod;
let accessToken;
let lastLogin;
let loginAttempts;
let current_app;
let username;
let organizationDetails;
let defaultCurrency;
let defaultCountry;
let attempts;
let defaultApp;

/**
 * @function mapResponseToError
 * @name mapResponseToError
 * @description convert axios error to js native error
 * @param {object} exception axios http error response
 * @returns {Promise} promise rejection
 * @see {@link https://github.com/axios/axios#handling-errors}
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const mapResponseToError = (exception) => {
  // obtain error details
  let { code, status, message, description, stack, errors, data } = exception;
  const { request, response } = exception;
  // handle server response error
  if (response) {
    code = response.code || code;
    status = response.status || status;
    data = response.data || data || {};
    message =
      response.status === undefined || status === undefined
        ? "We're having trouble processing your request. Please try again later or contact support for assistance"
        : data.message || response.statusText || message;
    errors = response.errors || errors || {};
    stack = response.stack || data.stack || stack;
  }

  // handle no server response
  if (request) {
    description = description || 'Server Not Responding';
  }

  // initialize error
  let error = new Error(message);
  error.stack = stack;

  // update error object
  error = merge(error, {
    code,
    status,
    message:
      status === undefined
        ? "We're having trouble processing your request. Please try again later or contact support for assistance"
        : message,
    description,
    errors,
    ...data,
    ...response,
  });

  // return normalized native error
  return Promise.reject(error);
};

/**
 * @function mapResponseToData
 * @name mapResponseToData
 * @description convert axios http response to data
 * @param {object} response axios http response
 * @returns {object} response data
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const mapResponseToData = (response) => response.data;

/**
 * @function wrapRequest
 * @name wrapRequest
 * @description wrap http request and convert response to error or data
 * @param {Promise} request valid axios http request object
 * @returns {Promise} request with normalized response error and data
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const wrapRequest = (request) => {
  return request.then(mapResponseToData).catch(mapResponseToError);
};

/**
 * @name CONTENT_TYPE
 * @description supported content type
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 */
export const CONTENT_TYPE = 'application/json';

/**
 * @function createHttpClient
 * @name createHttpClient
 * @description create an http client if not exists
 * @param {string} API_BASE_URL base url to use to api calls
 * @returns {object} A new instance of Axios
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 * import { createHttpClient } from './client';
 * const httpClient = createHttpClient();
 */
export const createHttpClient = (API_BASE_URL) => {
  if (!client) {
    BASE_URL =
      API_BASE_URL ||
      process.env.BEEM_API_URL ||
      process.env.REACT_APP_BEEM_API_URL;

    const options = { baseURL: BASE_URL, headers: HEADERS };
    client = axios.create(options);
    client.id = Date.now();
  }

  return client;
};

/**
 * @function disposeHttpClient
 * @name disposeHttpClient
 * @description reset current http client in use.
 * @returns {object} null
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { disposeHttpClient } from './client';
 * disposeHttpClient();
 */
export const disposeHttpClient = () => {
  client = null;
  return client;
};

/**
 * @function all
 * @name all
 * @description performing multiple concurrent requests.
 * @param {Function[]} promises Array of function to be run in parallel
 * @returns {Promise} Promise Instance
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { all, spread } from './client';
 * const request = all(getProfile(), getPlans());
 * request.then(spread((incidentTypes, plans) => { ... }));
 */
export const all = (...promises) => axios.all([...promises]);

/**
 * @function spread
 * @name spread
 * @description Flattened array fulfillment to the formal parameters of the
 * fulfillment handler.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { all, spread } from './client';
 * const request = all(getProfile(), getPlans());
 * request.then(spread((incidentTypes, plans) => { ... }));
 */
export const spread = axios.spread; // eslint-disable-line

/**
 * @function prepareParams
 * @name prepareParams
 * @description convert api query params as per API filtering specifications
 * @param {object} params api call query params
 * @returns {object} http params to be sent to server
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 */
export const prepareParams = (params) => {
  const normalizedParams = {};
  const { sort, page, pageSize, ...otherParams } = params || {};

  if (sort) {
    forEach(params.sort, (value, key) => {
      normalizedParams.sortBy = key;
      normalizedParams.sortOrder = value;
    });
  }

  if (page) {
    normalizedParams.page = params.page;
  }

  if (pageSize) {
    normalizedParams.pageSize = params.pageSize;
  }

  if (otherParams) {
    forEach(otherParams, (value, key) => {
      normalizedParams[key] = value;
    });
  }

  return normalizedParams;
};

/**
 * @function get
 * @name get
 * @description issue http get request to specified url.
 * @param {string} url valid http path.
 * @param {object} [params] params that will be encoded into url query params.
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { get } from './client';
 *
 * // list
 * const getUsers = get('/users', { age: 10 });
 * getUsers.then(users => { ... }).catch(error => { ... });
 *
 * // single
 * const getUser = get('/users/12');
 * getUser.then(user => { ... }).catch(error => { ... });
 */
export const get = (url, params) => {
  const httpClient = createHttpClient();
  const options = prepareParams(params);
  return wrapRequest(httpClient.get(url, { params: options }));
};

/**
 * @function post
 * @name post
 * @description issue http post request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { post } from './client';
 *
 * const postUser = post('/users', { age: 14 });
 * postUser.then(user => { ... }).catch(error => { ... });
 */
export const post = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.post(url, data));
};

/**
 * @function put
 * @name put
 * @description issue http put request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { put } from './client';
 *
 * const putUser = put('/users/5c1766243c9d520004e2b542', { age: 11 });
 * putUser.then(user => { ... }).catch(error => { ... });
 */
export const put = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.put(url, data));
};

/**
 * @function patch
 * @name patch
 * @description issue http patch request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { patch } from './client';
 *
 * const patchUser = patch('/users/5c1766243c9d520004e2b542', { age: 10 });
 * patchUser.then(user => { ... }).catch(error => { ... });
 */
export const patch = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.patch(url, data));
};

/**
 * @function del
 * @name del
 * @description issue http delete request to specified url.
 * @param {string} url valid http path.
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { del } from './client';
 *
 * const deleteUser = del('/users/5c1766243c9d520004e2b542');
 * deleteUser.then(user => { ... }).catch(error => { ... });
 */
export const del = (url) => {
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.delete(url));
};

/**
 * @function
 * @name normalizeResource
 * @description Normalize resource by adding singular and plural
 * names to it
 * @param {string} resource valid api resource name
 * @returns {object} Object contains singular and plural names for resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const normalizeResource = (resource) => {
  const singular = singularize(resource.name);
  const plural = pluralize(resource.name);

  return { singular, plural };
};

/**
 * @function
 * @name variableNameFor
 * @description Generate camel cased variable name based on provided strings
 * @param  {...string} names array of names to be used in variable name
 * @returns {string} camel cased name
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const variableNameFor = (...names) => camelCase([...names]);

/**
 * @function
 * @name createGetListHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createGetListHttpAction = (resource) => {
  const { plural } = normalizeResource(resource);

  const methodName = variableNameFor('get', plural);

  return {
    [methodName]: (params) => {
      const endpoint = `/${plural}`;

      return get(endpoint, params);
    },
  };
};

/**
 * @function
 * @name createGetSingleHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createGetSingleHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('get', singular);

  return {
    [methodName]: (id) => {
      const endpoint = `/${plural}/${id}`;

      return get(endpoint);
    },
  };
};

/**
 * @function
 * @name createPostHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPostHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('post', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}`;

      return post(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createPutHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPutHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('put', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}/${payload.id}`;

      return put(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createPatchHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPatchHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('patch', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}/${payload.id}`;

      return patch(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createDeleteHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createDeleteHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('delete', singular);

  return {
    [methodName]: (id) => {
      const endpoint = `/${plural}/${id}`;

      return del(endpoint);
    },
  };
};

/**
 * @function createHttpActionsFor
 * @name createHttpActionsFor
 * @description generate http actions to interact with resource
 * @param {string} resource valid http resource
 * @returns {object} http actions to interact with a resource
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { createHttpActionsFor } from './client';
 *
 * const { deleteUser } = createHttpActionsFor('user');
 * deleteUser('5c176624').then(user => { ... }).catch(error => { ... });
 */
export const createHttpActionsFor = (resource) => {
  const getResources = createGetListHttpAction(resource);
  const getResource = createGetSingleHttpAction(resource);
  const postResource = createPostHttpAction(resource);
  const putResource = createPutHttpAction(resource);
  const patchResource = createPatchHttpAction(resource);
  const deleteResource = createDeleteHttpAction(resource);

  return {
    ...getResources,
    ...getResource,
    ...postResource,
    ...putResource,
    ...patchResource,
    ...deleteResource,
  };
};

/**
 * @function singin
 * @name singin
 * @description Signin user with provided credentials
 * @param {object} credentials Username and password
 * @returns {object} Object having party, permission and other meta data
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 * import { signin } from './client';
 *
 * signin({ email:'', password:'' }).then(results => {});
 */
export const signin = (credentials) => {
  const defaultCredentials = { email: '', password: '' };
  const payload = isEmpty(credentials)
    ? defaultCredentials
    : merge(defaultCredentials, credentials);

  return post('/auth/login', payload).then((results) => {
    // persist token and party in session storage
    localStorage.setItem('token', results.data.token); // eslint-disable-line
    localStorage.setItem('userId', results.data.id); // eslint-disable-line
    userId = results.data.id;
    jwtToken = results.data.access_token;

    return results;
  });
};

/**
 * @function
 * @name signout
 * @description Signout function and cleanup localStorage
 * @param {object} token Base64 Encoded jwt token
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const signout = (token) => {
  return axios
    .delete(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/logout`, {
      data: {
        short_token: token,
      },
    })
    .then(() => {
      Cookies.remove('payload');
      Cookies.remove('token');
      window.location.replace(String(process.env.REACT_APP_AUTH_REDIRECT_URL)); // eslint-disable-line
    })
    .catch(() => {
      Cookies.remove('payload');
      Cookies.remove('token');
      window.location.replace(String(process.env.REACT_APP_AUTH_REDIRECT_URL)); // eslint-disable-line
    });
};

/**
 * @function
 * @name getJwtToken
 * @description retrieve jwt token from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getJwtToken = () => {
  if (isEmpty(jwtToken)) {
    jwtToken = Cookies.get('token');
  }
  return jwtToken;
};

/**
 * @function
 * @name getAccessToken
 * @description retrieve access token from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getAccessToken = () => {
  if (isEmpty(accessToken)) {
    const payload = Cookies.getJSON('payload');
    accessToken = lodashGet(payload, 'access_token', '');
  }
  return accessToken;
};

/**
 * @function
 * @name getLastLogin
 * @description retrieve last login from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getLastLogin = () => {
  if (isEmpty(lastLogin)) {
    const payload = Cookies.getJSON('payload');
    lastLogin = lodashGet(payload, 'last_login', '');
  }
  return lastLogin;
};

/**
 * @function
 * @name getLoginAttempts
 * @description retrieve last login from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getLoginAttempts = () => {
  if (isEmpty(loginAttempts)) {
    const payload = Cookies.getJSON('payload');
    loginAttempts = lodashGet(payload, 'login_attempts', '');
  }
  return loginAttempts;
};

/**
 * @function
 * @name getOrganizationInfo
 * @description retrieve organization from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getOrganizationInfo = () => {
  if (isEmpty(organizationDetails)) {
    const payload = Cookies.getJSON('payload');
    organizationDetails = lodashGet(payload, 'organization', '');
  }
  return organizationDetails;
};
/**
 * @function
 * @name getExpiryTime
 * @description Retrieve token expiry timestamp
 *
 * @returns {number} expiry timestamp
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const getExpiryTime = () => {
  if (isEmpty(expiryTime)) {
    const payload = Cookies.getJSON('payload');
    expiryTime = lodashGet(payload, 'exp', '');
  }
  return expiryTime;
};

/**
 * @function
 * @name getUserId
 * @description retrieve userId from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getUserId = () => {
  if (isEmpty(userId)) {
    const payload = Cookies.getJSON('payload');
    userId = lodashGet(payload, 'user_id', '');
  }
  return userId;
};

export const getUserEmail = () => {
    const payload = Cookies.getJSON('payload');
   const email = lodashGet(payload, 'email', '');
  return email;
};

/**
 * @function
 * @name getRole
 * @description retrieve role from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getRole = () => {
  if (isEmpty(role)) {
    const payload = Cookies.getJSON('payload');
    role = lodashGet(payload, 'role', 'vendor');
  }
  return role;
};

/**
 * @function
 * @name getLocale
 * @description retrieve locale from local storage if not set
 *
 * @returns {string |undefined} locale i.e en, sw
 * @since 0.1.0
 * @version 0.1.0
 */
export const getLocale = () => {
  if (isEmpty(locale)) {
    const payload = Cookies.getJSON('payload');
    locale = lodashGet(payload, 'locale', 'en');
  }

  return locale;
};

/**
 * @function
 * @name getApps
 * @description retrieve apps Url from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getApps = () => {
  if (isEmpty(apps)) {
    const payload = Cookies.getJSON('payload');
    apps = JSON.stringify(lodashGet(payload, 'apps', ''));
  }

  return apps;
};

/**
 * @function
 * @name getAuthUrl
 * @description retrieve authentication app Url from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getAuthUrl = () => {
  if (isEmpty(authUrl)) {
    const payload = Cookies.getJSON('payload');
    authUrl = JSON.stringify(lodashGet(payload, 'auth_url', ''));
  }

  return authUrl;
};

/**
 * @function
 * @name getThemeColor
 * @description retrieve application theme color from local storage if not set
 *
 * @returns {string |undefined} theme color
 * @since 0.1.0
 * @version 0.1.0
 */
export const getThemeColor = () => {
  if (isEmpty(themeColor)) {
    const payload = Cookies.getJSON('payload');
    if (!isEmpty(payload)) {
      themeColor = lodashGet(payload.parent_profile, 'theme_color', '#33b1ba');
    } else {
      themeColor = lodashGet(payload, 'theme_color', '#33b1ba');
    }
  }
  return themeColor;
};

/**
 * @function
 * @name getLogoUrl
 * @description retrieve application logo from local storage if not set
 *
 * @returns {string |undefined} logo url
 * @since 0.1.0
 * @version 0.1.0
 */
export const getLogoUrl = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(logoUrl)) {
    if (!isEmpty(payload)) {
      logoUrl = lodashGet(payload.parent_profile, 'logo_url', '');
    }
  }

  return logoUrl;
};

/**
 * @function
 * @name getSupportPhone
 * @description retrieve support phone from local storage if not set
 *
 * @returns {string |undefined} support phone number
 * @since 0.1.0
 * @version 0.1.0
 */
export const getSupportPhone = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(supportPhone)) {
    if (!isEmpty(payload)) {
      supportPhone = lodashGet(payload.parent_profile, 'support_phone', '');
    }
  }

  return supportPhone;
};

/**
 * @function
 * @name getPhone
 * @description retrieve phone from local storage if not set
 *
 * @returns {string |undefined} phone
 * @since 0.1.0
 * @version 0.1.0
 */
export const getPhone = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(phone)) {
    if (!isEmpty(payload)) {
      phone = lodashGet(payload.parent_profile, 'phone', '');
    }
  }

  return phone;
};

/**
 * @function
 * @name getOrganization
 * @description retrieve organization name color from local storage if not set
 *
 * @returns {string |undefined} organization name
 * @since 0.1.0
 * @version 0.1.0
 */
export const getOrganization = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(organization)) {
    if (!isEmpty(payload)) {
      organization = lodashGet(payload.parent_profile, 'organisation', '');
    }
  }

  return organization;
};

/**
 * @function
 * @name getPaymentMethod
 * @description Retrieve payment method set by reseller for sub-accounts
 *
 * @returns {string |undefined} payment method for sub-account
 * @since 0.1.0
 * @version 0.1.0
 */
export const getPaymentMethod = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(paymentMethod)) {
    if (!isEmpty(payload)) {
      paymentMethod = lodashGet(payload.parent_profile, 'payment_method', '');
    }
  }

  return paymentMethod;
};

/**
 * @function
 * @name getCurrentApp
 * @description retrieve current application details from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getCurrentApp = () => {
  if (isEmpty(current_app)) {
    const payload = Cookies.getJSON('payload');
    current_app = JSON.stringify(lodashGet(payload, 'current_app', ''));
  }

  return current_app;
};

export const getCurrentAppName = () => {
  // eslint-disable-next-line no-undef
  const url = window.location.hash.split('/')[2];
  return (
    (url && url === 'multi-sms' ? 'emg' : url).toUpperCase() ||
    process.env.REACT_APP_APP_NAME.toUpperCase()
  );
};

export const getCurrentAppDetails = () => {
  const currentApp = JSON.parse(getApps()).find(
    (app) => app.name === getCurrentAppName()
  );
  return currentApp;
};

/**
 * @function
 * @name getUsername
 * @description retrieve username
 *
 * @returns {string |undefined} username
 * @since 0.1.0
 * @version 0.1.0
 */
export const getUsername = () => {
  if (isEmpty(username)) {
    const payload = Cookies.getJSON('payload');
    username = lodashGet(payload, 'username', '');
  }
  return username;
};

/**
 * @function
 * @name getDefaultApp
 * @description retrieve username
 *
 * @returns {string |undefined} username
 * @since 0.1.0
 * @version 0.1.0
 */
export const getDefaultApp = () => {
  if (isEmpty(defaultApp)) {
    const payload = Cookies.getJSON('payload');
    defaultApp = lodashGet(payload, 'defaultApp', '');
  }
  return defaultApp;
};

/**
 * @function
 * @name getDefaultCurrency
 * @description Retrieve token expiry timestamp
 *
 * @returns {number} expiry timestamp
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDefaultCurrency = () => {
  if (isEmpty(defaultCurrency)) {
    const payload = Cookies.getJSON('payload');
    defaultCurrency = lodashGet(payload, 'currency', '');
  }
  return defaultCurrency;
};
/**
 * @function
 * @name getDefaultCountry
 * @description Retrieve token expiry timestamp
 *
 * @returns {number} expiry timestamp
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDefaultCountry = () => {
  if (isEmpty(defaultCountry)) {
    const payload = Cookies.getJSON('payload');
    defaultCountry = lodashGet(payload, 'country', '');
  }
  return defaultCountry;
};

/**
 * @function
 * @name getLoginDetails
 * @description Retrieve login attempts for apps
 *
 * @returns {number} expiry timestamp
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getLoginDetails = () => {
  if (isEmpty(attempts)) {
    const payload = Cookies.getJSON('payload');
    attempts = lodashGet(payload, 'attempts', '');
  }
  return attempts;
};
/**
 * @function
 * @name saveToken
 * @description Save token to localStorage
 * @param {object} token Base64 Encoded jwt token
 * @param {string} client_id client_id string
 * @returns {Promise} Promise response on fetching vendor balance
 * @version 0.4.0
 * @since 0.1.0
 */
export const saveToken = (token, client_id) => {
  const defaultCredentials = Cookies.get('token');
  const payload = isEmpty(token) ? defaultCredentials : token;
  jwtToken = payload;

  const body = { short_token: payload };
  if (client_id) {
    body.client_id = client_id;
  }
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/getUserData`,
    {
      ...body,
    }
  ).then((response) => {
    const debug = process.env.NODE_ENV === 'test';
    Cookies.set('payload', response.data, { secure: debug });
    Cookies.set('token', response.data.token, { secure: debug });
    userId = response.data.user_id;
    role = response.data.role;
    locale = response.data.locale;
    apps = response.data.apps;
    expiryTime = response.data.exp;
    authUrl = response.data.auth_url;
    accessToken = response.data.access_token;
    themeColor = '#33b1ba';
    current_app = response.data.current_app;
    username = response.data.username;
    loginAttempts = response.data.login_attempts;
    attempts = response.data.attempts;
    organizationDetails = response.data.organization;
    defaultApp = response.data.defaultApp;
    defaultCountry = response.data.country;
    defaultCurrency = response.data.currency;
    lastLogin =
      response.data.last_login === 'null' ? 'null' : response.data.last_login;

    Cookies.set('lastLogin', lastLogin, { secure: debug });
    Cookies.set('loginAttempts', loginAttempts, { secure: debug });
    Cookies.set('organization', organizationDetails, { secure: debug });
    Cookies.set('attempts', attempts, { secure: debug });

    if (!isEmpty(response.data.parent_profile)) {
      themeColor = response.data.parent_profile.theme_color;
      logoUrl = response.data.parent_profile.logo_url;
      supportPhone = response.data.parent_profile.support_phone;
      phone = response.data.parent_profile.phone;
      organization = response.data.parent_profile.organisation;
      paymentMethod = response.data.parent_profile.payment_method;
    }
    window.location.replace(`/#!/?token=${token}`); // eslint-disable-line
    return response.data;
  });
};

/**
 * @function
 * @name isValidUser
 * @description Valid if the current user is valid
 * @returns {boolean} True if valid and false otherwise
 * @version 0.1.0
 * @since 0.1.0
 */
export const isValidUser = () => {
  jwtToken = getJwtToken();

  userId = getUserId();

  expiryTime = getExpiryTime();
  if (isEmpty(jwtToken) || isEmpty(userId)) {
    return false;
  }

  if (expiryTime && expiryTime > Math.round(Date.now() / 1000)) {
    return true;
  }

  return false;
};

/**
 * @name HEADERS
 * @description default http headers
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 */
export const HEADERS = {
  Accept: CONTENT_TYPE,
  'Content-Type': CONTENT_TYPE,
  Authorization: getJwtToken(),
};
// TODO: Check on jwtToken
/**
 * @function
 * @name setTour
 * @description Signout function and cleanup localStorage
 *
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const setTour = () => {
  Cookies.set('lastLogin', 'null');
  Cookies.set('loginAttempts', 'disabled');
  // eslint-disable-next-line no-undef
  window.location.replace(`${process.env.REACT_APP_SMS_URL}`);
};

/**
 * @function
 * @name getVendorBalance
 * @description Retrieve vendor balance for a single vendor
 *
 * @param {object} params Filtering params
 * @returns {Promise} Promise response on fetching vendor balance
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getVendorBalance = (params) => {
  return get('/vendors/balance', params);
};

/**
 * @function
 * @name getDefaultSchemes
 * @description Retrieve vendor balance for a single vendor
 * @param {object} data Filter data
 * @param {object} params Filtering params
 * @returns {Promise} Promise response on fetching vendor balance
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDefaultSchemes = (data, params) => {
  return get(`/plans/country/${data}`, params);
};

/**
 * @function
 * @name getCountries
 * @description Get countries which are static values
 * @param {object} params additional param for search countries
 *
 * @returns {Promise} Promise which resolves to data
 * @version 0.1.0
 * @since 0.1.0
 */
export const getCountries = (params) => {
  return get('/static/countries', params);
};

/**
 * @function
 * @name getOAuthCountries
 * @description Get countries which are static values
 * @param {object} params additional param for search countries
 *
 * @returns {Promise} Promise which resolves to data
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOAuthCountries = (params) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/static/countries`,
    params
  );
};

/**
 * @function
 * @name getCities
 * @description Get cities which are static values
 *
 * @returns {Promise} Promise which resolves to data
 * @version 0.1.0
 * @since 0.1.0
 */
export const getCities = () => {
  return get('/static/cities');
};

/**
 * @function
 * @name validateContacts
 * @description Upload contacts for validation
 * @param {*} data  contacts to be uploaded
 * @returns {Promise} Post request to the API
 * @version 0.1.0
 * @since 0.1.0
 */
export const validateContacts = (data) => {
  return post('/contacts/validate', data);
};

/**
 * @function
 * @name importContacts
 * @description Import contacts to the API after validation
 * @param {*} data contacts to be imported
 * @returns {Promise} Post Request
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const importContacts = (data) => {
  return post('/contacts/import', data);
};

/**
 * @function
 * @name validateSMSContacts
 * @description Validate Group SmS contacts
 * @param {*} data Data to be send for validation
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const validateSMSContacts = (data) => {
  return post(`${process.env.REACT_APP_BRAODCAST_API_URL}/broadcast`, data);
};

/**
 * @function
 * @name composeSMS
 * @description Send SMS template for composition
 * @param {*} data Data to be send for validation
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const composeSMS = (data) => {
  return post(
    `${process.env.REACT_APP_BRAODCAST_API_URL}/broadcast/compose`,
    data
  );
};

/**
 * @function
 * @name sendSMS
 * @description Send SMS to validated numbers
 * @param {object} data  Data object contains redis reference key for sending SMS
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const sendSMS = (data) => {
  return post(
    `${process.env.REACT_APP_BRAODCAST_API_URL}/broadcast/send`,
    data
  );
};

export const completePurchase = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests/complete`,
    data
  );
};

/**
 * @function
 * @name getPurchaseHistory
 * @description get Purchase history for current log gin vendor
 * @param {object} params Additional params for getting results
 *
 * @returns {Promise} Get Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const getPurchaseHistory = (params) => {
  return get(`/vendors/purchase-histories/${getUserId()}`, params);
};
/**
 * -----------------------------------------------------------------------------
 * API Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name postAPI
 * @description Add API
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postAPI = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/generate-apiKey`,
    data
  );
};

/**
 * @function
 * @name putAPI
 * @description Edit API
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const putAPI = (data) => {
  return put(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/disable-apikey`,
    data
  );
};

/**
 * @function
 * @name getAPI
 * @description Get API
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAPI = (params) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/apikey`,
    params
  );
};
/**
 * @function
 * @name postSecret
 * @description Add API
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postSecret = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/generate-secretkey`,
    data
  );
};

export const resetAPIKey = (data) => {
  return put(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/reset-apikey/${data.id}`,
    data
  );
};

/**
 * @function
 * @name getRoles
 * @description Get API
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRoles = (params) => {
  return get(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/roles`, params);
};

/**
 * @function
 * @name postOrganization
 * @description Add API
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postOrganization = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/organization`,
    data
  );
};
/**
 * -----------------------------------------------------------------------------
 * Reports Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name getBroadcastReport
 * @description Get Broadcast Report
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getBroadcastReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/broadcast`, params);
};

/**
 * @function
 * @name getBroadcastStatsReport
 * @description Get Broadcast Statistic Report
 * @param {object} params Params object
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getBroadcastStatsReport = (params) => {
  const { smsStoreId, ...otherParams } = params;
  return get(
    `${process.env.REACT_APP_REPORT_URL}/reports/broadcast/${smsStoreId}`,
    otherParams
  );
};

/**
 * @function
 * @name getAllSMSReport
 * @description Get All SMS Report
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAllSMSReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/all_sms`, params);
};

/**
 * @function
 * @name getAllSMSReport
 * @description Get All SMS Report
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAPIReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/api_sms`, params);
};

/**
 * @function
 * @name getNetworkReport
 * @description Get Network Report
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getNetworkReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/network_sms`, params);
};

/**
 * @function
 * @name getNetworkReport
 * @description Get Received Report
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getReceivedReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/received`, params);
};

/**
 * @function
 * @name getScheduledSMSReport
 * @description Get Scheduled SMS Report
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getScheduledSMSReport = (params) => {
  return get(
    `${process.env.REACT_APP_REPORT_URL}/reports/scheduled_sms`,
    params
  );
};

/**
 * @function
 * @name updateScheduleSMSReport
 * @description PUT Schedule SMS Report
 * @param {object} data updated SMS schedule
 * @returns {Promise} PUT promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const updateScheduleSMSReport = (data) => {
  return put(
    `${process.env.REACT_APP_REPORT_URL}/reports/scheduled_sms/${data.sms_store_id}`,
    data
  );
};

/**
 * @function
 * @name deleteScheduledBroadcastReport
 * @description DELETE Scheduled Broadcast Report
 * @param {object} data deleted broadcast schedule
 * @returns {Promise} DELETE promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteScheduledBroadcastReport = (data) => {
  const id = data.sms_store_id;
  return del(
    `${process.env.REACT_APP_REPORT_URL}/reports/scheduled_sms/${id}`, // ${process.env.REACT_APP_REPORT_URL}
    id
  );
};

/**
 * @function
 * @name getVendorDashboard
 * @description Get Vendor Dashboard
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getVendorDashboard = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/dashboard`, params);
};

/**
 * @function
 * @name getExportsReport
 * @description Get Exports report
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getExportsReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/export`, params);
};

/**
 * @function
 * @name generateExport
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const generateExport = (data) => {
  return post(`${process.env.REACT_APP_REPORT_URL}/reports/exports`, data);
};

/**
 * @function
 * @name generatePurchaseExport
 * @description Generate Purchase Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const generatePurchaseExport = (data) => {
  return post(`${process.env.REACT_APP_REPORT_URL}/purchases/exports`, data); // ${process.env.REACT_APP_REPORT_URL}
};

/**
 * @function
 * @name getPurchaseExportsReport
 * @description Get Purchases Exports report
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getPurchaseExportsReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/purchases/export`, params);
};

/**
 * @function
 * @name generatePaymentExport
 * @description Generate Payment Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const generatePaymentExport = (data) => {
  return post(`${process.env.REACT_APP_REPORT_URL}/payments/exports`, data);
};

/**
 * @function
 * @name getPaymentExportsReport
 * @description Get Payment Exports report
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getPaymentExportsReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/payments/export`, params);
};

/**
 * @function
 * @name generateBroadcastExport
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const generateBroadcastExport = (data) => {
  return post(
    `${process.env.REACT_APP_REPORT_URL}/reports/broadcast/export`,
    data
  );
};

/**
 * @function
 * @name generateTwoWayExport
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const generateTwoWayExport = (data) => {
  return post(
    `${process.env.REACT_APP_REPORT_URL}/reports/twoway/export`,
    data
  );
};

/**
 * @function
 * @name generateContactExport
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const generateContactExport = (data) => {
  return post(`${process.env.REACT_APP_CONTACT_URL}/contacts/export`, data);
};

/**
 * @function
 * @name bulkDeleteContacts
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const bulkDeleteContacts = (data) => {
  return axios.delete(
    `${process.env.REACT_APP_CONTACT_URL}/contacts/bulkContacts`,
    {
      data,
      headers: HEADERS,
    }
  );
};

/**
 * @function
 * @name bulkDeleteAddressBooks
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const bulkDeleteAddressBooks = (data) => {
  return axios.delete(
    `${process.env.REACT_APP_CONTACT_URL}/contacts/bulkAddressbooks`,
    {
      data,
      headers: HEADERS,
    }
  );
};

/**
 * -----------------------------------------------------------------------------
 * Reseller Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name assignCredits
 * @description Assign credits to sub-accounts or vendors
 *
 * @param {object} data  Assign credit data
 * @returns {Promise} Post promise response
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const assignCredits = (data) => {
  return post('/reseller-credits', data);
};
/**
 * @function
 * @name sendForm
 * @description Add credits to sub-accounts or vendors
 *
 * @param {object} data  Add credit data
 * @returns {Promise} Post promise response
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const sendForm = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/receive/sms`,
    data
  );
};

/**
 * -----------------------------------------------------------------------------
 * Admin Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name getAdminDashboard
 * @description Get Admin Dashboard
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAdminDashboard = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/admin`, params);
};

/**
 * @function
 * @name getVerifiedSenderIds
 * @description Get Admin Sender Ids for verifications
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getVerifiedSenderIds = (params) => {
  return get('/verify-senderids', params);
};

/**
 * @function
 * @name postVerifiedSenderIds
 * @description Post Admin Sender Ids for verifications
 * @param {object} data Sender id verification object
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postVerifiedSenderIds = (data) => {
  return post('/verify-senderids', data);
};

/**
 * @function
 * @name getRoutes
 * @description Get Routes in admin
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRoutes = (params) => {
  return get('/admins/routes', params);
};

/**
 * @function
 * @name postRoute
 * @description Create a new route in admin
 * @param {object} data  Route object
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postRoutes = (data) => {
  return post('/admins/routes', data);
};

/**
 * @function
 * @name getNetworkRoutes
 * @description Get Network routes
 * @param {object} params Filtering params
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getNetworkRoutes = (params) => {
  return get('/admins/network-routes', params);
};

/**
 * @function
 * @name postNetworkRoutes
 * @description Create a new network route in admin
 * @param {object} data  Network route object
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postNetworkRoute = (data) => {
  return post('/admins/network-routes', data);
};

/**
 * @function
 * @name deleteNetworkRoute
 * @description Delete existing network route in admin
 * @param {string|number} id Network route unique identification
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteNetworkRoute = (id) => {
  return del(`/admins/network-routes/${id}`);
};

/**
 * @function
 * @name deleteContacts
 * @description Delete existing network route in admin
 * @param {string|number} data params Network route unique identification
 * @param {string} params Delete contacts
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteContacts = (data, params) => {
  return axios.delete(
    `${process.env.REACT_APP_BEEM_API_URL}/contacts/${data}`,
    {
      params,
      headers: HEADERS,
    }
  );
};

export const putContacts = (id, data) => {
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.put(`/contacts/${id}`, data));
};

/**
 * @function
 * @name getClientNetworkRoutes
 * @description Get client network routes
 * @param {object} params Filtering params
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getClientNetworkRoutes = (params) => {
  return get('/admins/client-networks-routes', params);
};

/**
 * @function
 * @name postClientNetworkRoutes
 * @description Create a new client network route in admin
 * @param {object} data  Client network route object
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postClientNetworkRoutes = (data) => {
  return post('/admins/client-networks-routes', data);
};

/**
 * @function
 * @name deleteClientNetworkRoute
 * @description Delete existing client network route in admin
 * @param {string|number} id Client network route unique identification
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteClientNetworkRoute = (id) => {
  return del(`/admins/client-networks-routes/${id}`);
};

export const getAccountBalance = (params, path, app, emgUser, defaults) => {
  if (emgUser && emgUser === 'active' && (path === 'sms' || path === 'emg')) {
    return get(`${process.env.REACT_APP_EMG_AP_URL}/topup/balance`);
  }
  if (
    path === 'bpay' ||
    path === 'ussd' ||
    path === 'airtime' ||
    path === 'emg' ||
    path === 'broadcast' ||
    defaults === 'bpay' ||
    defaults === 'ussd' ||
    defaults === 'airtime' ||
    defaults === 'emg' ||
    defaults === 'broadcast'
  ) {
    return get(`${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance`, {
      app_name:
        path !== 'default' ? path.toUpperCase() : defaults.toUpperCase(),
    });
  }
  return get('/vendors/balance', params);
};

/**
 * @function
 * @name updateAccountPersonalization
 * @description Update user profile allow uploading images and other details
 *
 * @param {object} data Form Data instance
 *
 *
 * @returns {Promise} PUT Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const updateAccountPersonalization = (data) => {
  const id = data.get('id');
  return wrapRequest(axios({
    method: 'PUT',
    url: `${process.env.REACT_APP_BEEM_API_URL}/vendors/${id}`,
    data,
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: getJwtToken(),
    },
  }));
};

/**
 * @function
 * @name getNetworks
 * @description Get list of networks
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getNetworks = (params) => {
  return get('/networks', params);
};

/**
 * @function
 * @name postNetwork
 * @description Create a new network
 * @param {object} data POST network data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const postNetwork = (data) => {
  return post('/networks', data);
};

/**
 * @function
 * @name putNetwork
 * @description Update existing network
 * @param {object} data POST network data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const putNetwork = (data) => {
  return put('/networks', data);
};

/**
 * @function
 * @name putCountry
 * @description Update existing network
 * @param {object} data POST network data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const putCountry = (data) => {
  return put('/networks', data);
};

/**
 * @function
 * @name putTrackers
 * @description Update existing network
 * @param {object} data PUT tracker data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const putTrackers = (data) => {
  return put(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/trackers/${data.id}`,
    data
  );
};

/**
 * @function
 * @name getTrackers
 * @description Get existing trackers
 * @param {object} params GET tracker
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getTrackers = (params) => {
  return get(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/trackers`, params);
};

/**
 * @function
 * @name getWebActivity
 * @description Get web activity
 * @param {object} params GET activity
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getWebActivity = (params) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/web-activity`,
    params
  );
};

/**
 * @function
 * @name getWebActivitySegments
 * @description Get web activity segments
 * @param {object} params GET activity
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getWebActivitySegments = (params) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/web-activity-segments`,
    params
  );
};

/**
 * @function
 * @name getWebOrganization
 * @description Get web activity
 * @param {object} params GET activity
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getWebOrganization = (params) => {
  return get(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/details`, params);
};

/**
 * @function
 * @name getOrganizationDetails
 * @description Get web activity
 * @param {object} params GET activity
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOrganizationDetails = (params) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/organization`,
    params
  );
};

/**
 * @function
 * @name deleteNetwork
 * @description Delete existing network
 * @param {string} id Network ID
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteNetwork = (id) => {
  return del(`/networks/${id}`);
};

export const getPaymentMethods = (params) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods`,
    params
  );
};

export const postPaymentMethods = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods`,
    data
  );
};

export const putPaymentMethods = (data) => {
  return put(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods/${data.id}`,
    data
  );
};

export const deletePaymentMethods = (data) => {
  return del(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods/${data.id}`
  );
};

/**
 * @function
 * @name getOauthUsers
 * @description Get list of oauth users
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOauthUsers = (params) => {
  return get(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/users`, params);
};

/**
 * @function
 * @name getOauthUserById
 * @description Get list of oauth users
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOauthUserById = () => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/users/${getUserId()}`
  );
};

/**
 * @function
 * @name getAirtimeUser
 * @description Get list of oauth users
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAirtimeUser = () => {
  return get(`${process.env.REACT_APP_AIRTIME_URL}/v1/users/${getUserId()}`);
};

export const getOTPUser = () => {
  return get(`${process.env.REACT_APP_OTP_URL}/v1/users/${getUserId()}`);
};

export const getCreditLevelNotificationSettings = () => {
  return get(`${process.env.REACT_APP_NOTIFICATION_API_URL}/v1/credit-level-settings/${getUserId()}`);
};

export const upsertCreditLevelNotificationSettings = (data) => {
  return post(`${process.env.REACT_APP_NOTIFICATION_API_URL}/v1/credit-level-settings`, data);
};

/**
 * @function
 * @name postOAuth
 * @description Update existing OAuth User
 * @param {object} data PUT data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const postOAuth = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/register`,
    data
  );
};

/**
 * @function
 * @name putOAuth
 * @description Update existing OAuth User
 * @param {object} data PUT data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const putOAuth = (data) => {
  return put(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/profile`,
    data
  );
};

/**
 * @function
 * @name getAssignedCreditSchemes
 * @description Get list of vendors assigned to a credit scheme
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAssignedCreditSchemes = (params) => {
  return get('/client-plans', params);
};

/**
 * @function
 * @name deleteAssignedCreditScheme
 * @description Remove
 *  from assigned credit scheme
 * @param {string} id Network ID
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteAssignedCreditScheme = (id) => {
  return del(`/client-plans/${id}`);
};

/**
 * -----------------------------------------------------------------------------
 * Two Way SMS Endpoints
 * -----------------------------------------------------------------------------
 */
/**
 * @function
 * @name getAdminNumbers
 * @description Get paginated list of all numbers
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAdminNumbers = (params) => {
  return get(`${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin`, params);
};

/**
 * @function
 * @name postAdminNumbers
 * @description Post numbers
 * @param {object} data POST params
 * @returns {Promise} POST promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const postAdminNumbers = (data) => {
  return post(`${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin`, data);
};

export const putAdminNumbers = (id, data) => {
  return put(
    `${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin/${id}`,
    data
  );
};
/**
 * @function
 * @name deleteUserAssignedNumber
 * @description Put numbers
 * @param {object} data PUT params
 * @returns {Promise} PUT promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteAdminNumbers = (data) => {
  return del(
    `${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin/${data}`,
    data
  );
};
/**
 * @function
 * @name postAssignNumber
 * @description Put numbers
 * @param {object} data PUT params
 * @returns {Promise} PUT promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const postAssignNumber = (data) => {
  return post(
    `${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin/assign`,
    data
  );
};

export const putAssignNumber = (id, data) => {
  return patch(
    `${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin/assign/${id}`,
    data
  );
};

export const putUserAssignNumber = (id, data) => {
  return patch(
    `${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/user/update/callback/${id}`,
    data
  );
};

/**
 * @function
 * @name getUserAssignedNumbers
 * @description Put numbers
 * @param {object} params PUT params
 * @returns {Promise} PUT promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUserAssignedNumbers = (params) => {
  return get(`${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/user`, params);
};

export const getTwoWayUserReport = (params) => {
  return get(`${process.env.REACT_APP_REPORT_URL}/reports/twoway`, params);
};

export const getTwoWayAdminReport = (params) => {
  return get(
    `${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin/view/transactions`,
    params
  );
};

/**
 * @function
 * @name deleteAdminNumbers
 * @description Put numbers
 * @param {object} data PUT params
 * @returns {Promise} PUT promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteUserAssignedNumber = (data) => {
  return del(
    `${process.env.REACT_APP_TWO_WAY_SMS_URL}/number/admin/${data}`,
    data
  );
};

/**
 * -----------------------------------------------------------------------------
 * Superfluid Endpoints
 * -----------------------------------------------------------------------------
 */

export const getSegments = (data) => {
  return get(`${process.env.REACT_APP_URL}/getsegments`, data);
};

export const getCustomerList = (data) => {
  return post(`${process.env.REACT_APP_URL}/customerdets`, data);
};

export const sendHelpForm = (data) => {
  return post(`${process.env.REACT_APP_BEEM_API_URL}/target-segments`, data);
};

export const getFilteredGenericTemplates = (data) => {
  return get(
    `${process.env.REACT_APP_BEEM_API_URL}/generic-templates/filtered`,
    data
  );
};
/**
 *
 * Web Activity Columns and Options
 */

/**
 * @function
 * @name getSegmentOptions
 * @description get segment options from users db
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getWebActivitySegmentOptions = () => {
  // not involving any backend
  // hard coding
  const segmentOptions = {
    data: [
      {
        value: 'userrole',
        label: 'User Role',
        options: ['reseller', 'vendor'],
      },
      {
        value: 'app_name',
        label: 'App Name',
        options: ['SMS', 'AIRTIME', 'BPAY', 'USSD', 'OTP', 'CHATBOT'],
      },
      {
        value: 'country',
        label: 'Country',
        options: ['Tanzania', 'Other'],
      },
      {
        value: 'role',
        label: 'Role',
        options: [
          'Technology',
          'Sales',
          'Operations',
          'Marketing',
          'Management',
        ],
      },
      {
        value: 'industry',
        label: 'Industry',
        options: [
          'Business Services',
          'Technology',
          'Agriculture',
          'Marketing',
          'Manufacturing',
          'IT',
          'Retail',
        ],
      },
    ],
  };
  return segmentOptions;
};

// Explicit for SFL
export const sendFileData = (datas) => {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_URL}/upload`,
    data: datas,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const getAffiliatedata = (data) => {
  return get(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/affiliate`, data);
};

export const sendRequestPayout = (data) => {
  return post(`${process.env.REACT_APP_BEEM_API_URL}/affiliate`, data);
};

export const getAffiliatedataAdmin = (data) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/affiliate/admin`,
    data
  );
};

export const postCallbackApp = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/callback/appCallback`,
    data
  );
};

export const postEMGInquiryForm = (data) => {
  return post(`${process.env.REACT_APP_EMG_AP_URL}/inquiry`, data);
};

export const getEMGInquiry = (data) => {
  return get('/emg-inquiry', data);
};

export const getEMGInquiryUser = (data) => {
  return get('/emg-inquiry/user', data);
};

export const putEMGInquiryForm = (data) => {
  return put(`${process.env.REACT_APP_EMG_AP_URL}/inquiry/${data.id}`, data);
};

export const getUserById = (params) => {
  return get(`/vendors/${getUserId()}`, params);
};

export const getUserwebActivity = (params) => {
  return get(
    `${
      process.env.REACT_APP_AUTH_REDIRECT_URL
    }/api/web-activity/user?user_id=${getUserId()}&app_name=SMS`,
    params
  );
};

export const postPurchases = (data) => {
  return post(
    `${process.env.REACT_APP_BRAODCAST_API_URL}/topup/credit-requests`,
    data
  );
};

export const postPurchase = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests`,
    data
  );
};

export const getPurchases = (params) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests`,
    params
  );
};

export const getTableReports  = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_REDIRECT_URL}/reports/table`,
    params
  );
};

export const getUserBalance = async (params) => {
    const response = await get(
      `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance`,
      params
    );
    return response; 
 
};

export const getMessagesReports = async (params) => {
    const response = await get(
      `${process.env.REACT_APP_REPORTS_REDIRECT_URL}/reports/counts`,
      params
    );
    return response; 
};

export const postPushPurchase = (data) => {
  return post(`${process.env.REACT_APP_TOPUP_REDIRECT_URL}/request-pay`, data);
};

export const putPurchases = (data) => {
  return put(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests/${data.id}`,
    data
  );
};

export const getPayments = (params) => {
  return get(`${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments`, params);
};

export const getCreditHistory = (params) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-histories`,
    params
  );
};

export const addCredits = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/assign-credits`,
    data
  );
};

export const deductCredits = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance/deduct-account-balance`,
    data
  );
};

export const getTopupBalance = (data) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance`,
    data
  );
};

export const getBroadcastUsers = (params) => {
  return get(`${process.env.REACT_APP_MOJA_API_URL}/bots`, params);
};

export const getTags = (params) => {
  return get(`${process.env.REACT_APP_TAGGING_URL}/reports/tags`, params);
};

export const getCampaignSenderNames2 = (params) => {
  const url = process.env.REACT_APP_BEEM_API_URL.replace('/v1', '');
  return get(
    `${url}/v2/sender-names/campaign`,
    params
  );
};

export const getSenderNames = (params) => {
  const url = process.env.REACT_APP_BEEM_API_URL.replace('/v1', '');

  return get(`${url}/v2/sender-names`, params);
};

export const smsPreview = (data) => {
  return post(
    `${process.env.REACT_APP_BRAODCAST_API_URL}/broadcast/preview`,
    data
  );
};

export const getWhatsappTemplates = (params) => {
  return get(`${process.env.REACT_APP_MOJA_API_URL}/message-templates`, params);
};

export const getAddressBooks = (params) => {
  return get(`${process.env.REACT_APP_CONTACT_URL}/address-books`, params);
};
export const getContacts = (params) => {
  return get(`${process.env.REACT_APP_CONTACT_URL}/contacts`, params);
};
export const getTag = (params) => {
  return get(`${process.env.REACT_APP_CONTACT_URL}/reports/tags`, params);
};
export const getWhatsappChannels = (params) => {
  return get(`${process.env.REACT_APP_MOJA_API_URL}/bots`, params);
};

export const postUserBillingRates = (data) => {
  return post(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates/user`,
    data
  );
};
export const initializeUserBillingRates = (data) => {
  return post(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates/user/initialize`,
    data
  );
};

export const putUserBillingRates = (data) => {
  return put(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates/user/${data.id}`,
    data
  );
};

export const getUserBillingRates = (params) => {
  return get(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates/user`,
    params
  );
};

export const deleteUserBillingRates = (data) => {
  return del(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates/user/${data.id}`
  );
};

export const postBillingRates = (data) => {
  return post(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates`,
    data
  );
};

export const putBillingRates = (data) => {
  return put(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates/${data.id}`,
    data
  );
};

export const getBillingRates = (params) => {
  return get(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates`,
    params
  );
};

export const deleteBillingRates = (data) => {
  return del(
    `${process.env.REACT_APP_MOJA_API_URL}/whatsapp-billing-rates/${data.id}`
  );
};
