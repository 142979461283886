/**
 * This file contains system wide configs
 *
 * @since 0.1.0
 * @version 0.1.0
 */
import React from 'react';
import map from 'lodash/map';

export const ConfigsContext = React.createContext();
export const RolesContext = React.createContext();
export const AppContext = React.createContext();
export const LocaleContext = React.createContext();

export const DATE_FORMAT = 'dd-MM-yyyy';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm a';

export const DATE_FORMAT_DOB = 'YYYY-MM-DD';

export const SCHEDULE_SMS_DATE_FORMAT = 'yyyy-M-dd hh:mm a';

export const THEME = '';

export const TITLE_OPTIONS = [
  { value: 'Mr.', label: 'Mr. ' },
  { value: 'Mrs.', label: 'Mrs. ' },
  { value: 'Ms.', label: 'Ms. ' },
];

export const SCHEME_OPTIONS = [
  { value: 'reseller', label: 'Reseller' },
  { value: 'vendor', label: 'Vendor' },
];

export const DELETE_OPTIONS = [
  { value: 'selected', label: 'Delete Selected ' },
  { value: 'all', label: 'Delete All' },
];

export const GENDER = {
  male: 'Male',
  female: 'Female',
};

export const VENDOR_TYPES = {
  1: 'Individual',
  2: 'Organization',
};

export const VENDOR_TYPE = [
  { value: 'Individual', label: 'No' },
  { value: 'Organization', label: 'Yes' },
];

export const API_TYPES = {
  1: 'Active',
  0: 'Inactive',
};

export const TWOWAY_STATUS = {
  1: 'Active',
  0: 'Inactive',
};

export const SIZE_TYPES = [
  { value: '1-10', label: '1-10' },
  { value: '10-100', label: '10-100' },
  { value: '100-500', label: '100-500' },
  { value: '500-1000', label: '500-1000' },
  { value: '1000-above', label: '1000-above' },
];

export const ORGANIZATION = [
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Business Services', label: 'Business Services' },
  {
    value: 'Manufacturing & Consumer Goods',
    label: 'Manufacturing & Consumer Goods',
  },
  { value: 'Consumer Services', label: 'Consumer Services' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Government', label: 'Government' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Health Care', label: 'Health Care' },
  { value: 'Logistics & Transport', label: 'Logistics & Transport' },
  { value: 'Media', label: 'Media' },
  { value: 'Religious Organizations', label: 'Religious Organizations' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Trading or Distribution', label: 'Trading or Distribution' },
  { value: 'Others', label: 'Others' },
];

export const ROLE_OPTIONS = [
  { value: 'Sales', label: 'Sales' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Operations', label: 'Operations' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Management', label: 'Management' },
  { value: 'Other', label: 'Other' },
];

export const GOAL_OPTIONS = [
  {
    value: 'Keep in Touch with Current Customers',
    label: 'Keep in Touch with Current Customers',
  },
  { value: 'Reward Current Customers', label: 'Reward Current Customers' },
  {
    value: 'Understand or survey your current customers',
    label: 'Understand or survey your current customers',
  },
  { value: 'Reach New Customers', label: 'Reach New Customers' },
  { value: 'Verify Customer Identity', label: 'Verify Customer Identity' },
  {
    value: 'Interact or present information to customers',
    label: 'Interact or present information to customers',
  },
  { value: 'Verify Customer Identity', label: 'Verify Customer Identity' },
  {
    value: 'Collect or send payments to customers',
    label: 'Collect or send payments to customers',
  },
  {
    value: 'Reduce cost to support/sell to customers',
    label: 'Reduce cost to support/sell to customers',
  },
  {
    value: 'To Integrate API or services in my project',
    label: 'To Integrate API or services in my project',
  },
  {
    value: 'Others',
    label: 'Others',
  },
];

export const VENDOR_SMS_REMINDER = {
  0: 'Inactive',
  1: 'Active',
};

export const LIMIT_FILTERS = {
  50: {
    en: '50 Records Limit',
    fr: 'Limite de 50 enregistrements',
    sw: 'Ishia rekodi 50',
  },
  1000: {
    en: '1000 Records Limit',
    fr: 'Limite de 1000 enregistrements',
    sw: 'Ishia rekodi 1000',
  },
  10000: {
    en: '10k Records Limit',
    fr: 'Limite de 10k enregistrements',
    sw: 'Ishia rekodi 10k',
  },
  50000: {
    en: '50k Records Limit',
    fr: 'Limite de 50k enregistrements',
    sw: 'Ishia rekodi 50k',
  },
  all: {
    en: 'All',
    fr: 'All',
    sw: 'Rudisha zote',
  },
};

export const USER_ROLES = {
  vendor: 'Vendor',
  admin: 'Admin',
  reseller: 'Reseller',
  subaccount: 'Sub-Account',
  accounts: 'Admin Accounts',
  technical: 'Admin Technical',
  sales: 'Sales',
  support: 'Admin Support',
  senderid: 'Admin Sender Id',
  salesadmin: 'Admin Sales',
};

export const VENDOR_ACCOUNT_STATUSES = VENDOR_SMS_REMINDER;

/* used in sender names */
export const STATUSES = {
  pending: 'Pending',
  active: 'Active',
  inactive: 'InActive',
};

export const NETWORK_STATUS = {
  0: 'Active',
  1: 'Inactive',
};
export const PURCHASE_STATUSES = {
  allocated: 'Allocated',
  pending: 'Pending',
  rejected: 'Rejected',
};

export const BILLING_TYPE = {
  toll_free: 'Toll Free',
  premium: 'Premium',
  standard: 'Standard',
};

export const BILLING_TYPE_OPTIONS = map(BILLING_TYPE, (value, key) => ({
  value: key,
  label: value,
}));

export const CODE_TYPE = {
  shared: 'Shared',
  dedicated: 'Dedicated',
};

export const CODE_TYPE_OPTIONS = map(CODE_TYPE, (value, key) => ({
  value: key,
  label: value,
}));

export const NUMBER_TYPE = {
  longcode: 'Long Code',
  shortcode: 'Short Code',
};

export const NUMBER_TYPE_OPTIONS = map(NUMBER_TYPE, (value, key) => ({
  value: key,
  label: value,
}));

export const ALLOCATE_OPTIONS = {
  add: 'Add Credit',
  deduct: 'Deduct Credit',
};

export const SMS_STATUSES = ['DELIVERED', 'PENDING', 'EXPIRED', 'UNDELIVERED'];

export const PURCHASE_PAYMENT_STATUSES = {
  0: 'Not Paid',
  1: 'Paid',
  2: 'Partially Paid',
  3: 'Receipt Sent',
  4: 'Bonus',
};

export const SORT_DAYS = {
  7: '7 days',
  14: '14 days',
  30: '1 month',
  90: '3 months',
  180: '6 months',
};

export const PAYMENT_STATUSES = {
  0: 'Pending',
  1: 'Processed',
  2: 'Over Paid',
  3: 'Under Paid',
};

export const REPORT_DURATION_OLD = [
  { value: 'day', name: { en: 'This Day', fr: 'Ce jour', sw: 'Leo' } },
  {
    value: 'week',
    name: { en: 'This Week', fr: 'Cette semaine', sw: 'Wiki hii' },
  },
  {
    value: 'month',
    name: { en: 'This Month', fr: 'Ce mois', sw: 'Mwezi huu' },
  },
  {
    value: 'year',
    name: { en: 'This Year', fr: 'Cette année', sw: 'Mwaka huu' },
  },
];

export const REPORT_DURATIONS = [
  { value: 'day', name: 'Day' },
  { value: 'week', name: 'Week' },
  { value: 'month', name: 'Month' },
  { value: 'year', name: 'Year' },
];

export const OVERVIEW_REPORT_DURATIONS = [
  {
    value: 'days',
    label: {
      en: 'By days per month',
      fr: 'Par jours par mois',
      sw: 'kwa siku kwa Mwezi',
    },
  },
  {
    value: 'custom',
    label: {
      en: 'By days per month per year',
      fr: 'Par jours par mois par an',
      sw: 'Kwa mwezi kwa mwaka',
    },
  },
  {
    value: 'months',
    label: {
      en: 'By months per year',
      fr: 'Par jours par an',
      sw: 'kwa siku kwa mwaka',
    },
  },
];

export const MONTHS = [
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];

export const MONTHS_OPTIONS = map(MONTHS, (month) => ({
  value: month.value,
  label: month.name,
}));

export const CONTACT_SCHEMA = {
  Phone: 'mob_no',
  AdditionalPhone: 'mob_no2',
  Title: 'title',
  FirstName: 'fname',
  LastName: 'lname',
  Gender: 'gender',
  DOB: 'birth_date',
  Address: 'address',
  Area: 'area',
  City: 'city',
  Country: 'country',
  Email: 'email',
};

export const DEFAULT_BROADCAST_CACHE = {
  groups: [],
  includedContacts: [],
  excludedContact: [],
  additionalContacts: [],
  smsTemplate: null,
  message: '',
  scheduleDate: undefined,
  mobileNumbers: [],
  recipientColumn: null,
  file: null,
  fileContent: [],
  hasHeader: true,
};

export const DEFAULT_CONTACTS_CACHE = {
  file: null,
  fileContent: [],
  groups: [],
  hasHeader: true,
};

export const PURCHASE_PAYMENT_STATUS_OPTIONS = map(
  PURCHASE_PAYMENT_STATUSES,
  (value, key) => ({
    value: key,
    label: value,
  })
);

export const SORT_DAYS_OPTIONS = map(SORT_DAYS, (value, key) => ({
  value: key,
  label: value,
}));

export const PURCHASE_STATUS_OPTIONS = map(PURCHASE_STATUSES, (value, key) => ({
  value: key,
  label: value,
}));

export const ALLOCATE_STATUS_OPTIONS = map(ALLOCATE_OPTIONS, (value, key) => ({
  value: key,
  label: value,
}));

export const PAYMENT_STATUS_OPTIONS = map(PAYMENT_STATUSES, (value, key) => ({
  value: key,
  label: value,
}));

export const LIMIT_FILTER_OPTIONS = map(LIMIT_FILTERS, (value, key) => ({
  value: key,
  label: value,
}));

export const EMG_USE_CASE = [
  {
    value: 'transactional',
    label: 'Transactional',
  },
  {
    value: 'promotional',
    label: 'Promotional',
  },
  {
    value: 'promotional',
    label: 'Transactional & Promotional',
  },
];

export const PURCHASE_STATUS_TOPUP = {
  allocated: 'Allocated',
  pending: 'Pending',
  rejected: 'Rejected',
};

export const PURCHASE_STATUS_TOPUP_OPTIONS = map(
  PURCHASE_STATUS_TOPUP,
  (value, key) => ({
    value: key,
    label: value,
  })
);

export const PAYMENT_METHOD_TYPES = {
  collections: 'Collections',
  push: 'Checkout',
};

export const ALLOWED_BETA_USERS = [
  'c7f3b836-8bd5-4453-82ff-55414e173192',
  '36355639-9f4f-43d0-821b-6ee2c611bc10',
  '479958e8-8e81-4eaf-ba92-633947e66620',
  'arl@beem.co.tz',
  'fakeer@mklogistics.co.uk',
  'abbas@takimsholidays.com',
  'haruna.ndyanabo@larfage.com',
  'info@zana.co.tz',
  'appollo@lami.world',
  'info@empressfurniture.com',
  'sales@beem.africa',
  'support@beem.africa',
  'mustafa.s@beem.africa',
  'security@mcc.co.ke',
  'comfortmuseo@gmail.com',
];
